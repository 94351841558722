import {observer} from "mobx-react";
import {
  brandValidationSchema
} from "./constants";
import {FieldArray, FormikProvider, getIn, useFormik} from "formik";
import MDButton from "../../../../../components/MDButton";
import pxToRem from "../../../../../assets/theme/functions/pxToRem";
import {useEffect, useState} from "react";
import FormikInput from "../../../../../components/FormikInput";
import MDTypography from "../../../../../components/MDTypography";
import MDBox from "../../../../../components/MDBox";
import AdminLayout from "../../../../../components/AdminLayout";
import {EMPTY_INITIAL} from "./data";
import {BrandCategoriesForm} from "./components/BrandCategoriesForm";
import {PackagesForm} from "./components/PackagesForm";
import {DrawingsForm} from "./components/DrawingsForm";
import {openInNewTab, showMessage, useApi} from "../../../../../services/helpers";
import {useDebouncedValidate} from "../../../../../shared/formik_debounce_validate";
import {useNavigate, useParams} from "react-router-dom";
import {ROUTES} from "../../../../../services/constants";
import {Icon} from "@mui/material";
import {
  ActionsBox,
  Container,
  FieldsContainer, FileFieldsContainer,
  FormContainer,
  FormSection, ImagecardContainer, ImagecardDeleteIcon, ImageCardImage, ImagecardWrapContainer,
  Title
} from "../../../manage-products/manage_product/styles";
import InputDragAndDrop from "../../../../../components/InputDragAndDrop";


const ManageBrandPage = () => {
  const {id: editBrandId} = useParams();
  const api = useApi()
  const navigate = useNavigate();

  const [currentBranch, setCurrentBranch] = useState(null)
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState([])
  const [regions, setRegions] = useState([])
  const [companies, setCompanies] = useState([])
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [init, setInit] = useState(false)


  const getCompanies = ({search}) => {
    api.getCompanies({search}).then((result) => {
      if (result.kind === 'ok') {
        setCompanies(result.data.results)
      }
    })
  }

  const getRegions = ({search, company_id}) => {
    api.getRegions({search, company_id: selectedCompany || company_id}).then((result) => {
      if (result.kind === 'ok') {
        setRegions(result.data.results)
      }
    })
  }

  const getCategories = ({search}) => {
    api.getCategories({search: search, page: 1, ordering: '', page_size: 25}).then((result) => {
      if (result.kind === 'ok') {
        setCategories(result.data.results)
      }
    }).catch(reason => console.log(reason))
  }


  const onSave = (values) => {
    setLoading(true)
    if (!currentBranch) {
      api.createBrand(values).then(result => {
        if (result.kind === 'ok') {
          showMessage('Brand created successfully', 'success')
          navigate(ROUTES.ADMIN_BRAND)
        } else {
          formik.setErrors(result.errors)
          showMessage('Error creating brand, please correct incorrect inputs')
        }
      }).catch(error => {
        showMessage('Error creating brand, please try again')
      }).finally(() => setLoading(false))
    } else {
      api.updateBrand(values).then(result => {
        if (result.kind === 'ok') {
          showMessage('Brand updated successfully', 'success')
          navigate(ROUTES.ADMIN_BRAND)
        } else {
          showMessage('Error updating brand, please correct incorrect inputs')
          formik.setErrors(result.errors)
        }
      }).catch(error => {
        showMessage('Error updating brand, please try again')
      }).finally(() => setLoading(false))
    }
  }

  const formik = useFormik({
    initialValues: currentBranch || EMPTY_INITIAL,
    validateOnChange: false,
    validationSchema: brandValidationSchema,
    enableReinitialize: true,
    onSubmit: onSave,
  })


  useDebouncedValidate({
    validate: values => {
      formik.validateForm(values);
    },
    debounceTime: 200,
    values: formik.values,
  });

  useEffect(() => {
    if (editBrandId) {
      api.getBrand(editBrandId).then(result => {
        if (result.kind === 'ok') {
          setCurrentBranch(result.data)
          getRegions({search: '', company_id: result.data.company.id})
        } else {
          showMessage(result.kind === 'not-found' ? 'Brand requested not found' : 'Error fetching brand')
          navigate(ROUTES.ADMIN_BRAND)
        }
      }).catch(error => {
        showMessage('Error fetching brand')
        navigate(ROUTES.ADMIN_BRAND)
      })
    } else{
      getRegions({search: ''})
    }

    getCompanies({search: ''})
    getCategories({search: ''})
  }, [])


  useEffect(() => {
    getRegions({search: ''})
    if (init) {
      formik.setFieldValue('region', null)
    }
  }, [selectedCompany])

  useEffect(() => {
    setInit(true)
  }, [])


  return (
    <AdminLayout
      title={editBrandId !== undefined ? `Update Brand [${currentBranch?.name}]` : 'Create Brand'}
      hasNavigationBack={true}
      contentCentered={true}
    >
      <MDBox sx={Container}>
        {/*<MDTypography variant={"p"} component={"p"} color={"error"} mt={2}>{JSON.stringify(formik.errors)}</MDTypography>*/}
        <MDTypography sx={Title}>Brand Information</MDTypography>
        <MDBox sx={FormContainer}>
          <FormikProvider value={formik}>
            <MDBox sx={FormSection}>
              <MDBox sx={FieldsContainer}>
                <FormikInput name="name" label="Name" mb={2}/>
                <FormikInput
                  fullWidth
                  value={formik.values.company}
                  type={"autocomplete"}
                  fieldName={'company'}
                  label={"Company"}
                  options={companies}
                  accessKey={"name"}
                  styleContainer={{mb: 2}}
                  onInputChange={(value) => {
                    getCompanies({search: value})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('company', value)
                    setSelectedCompany(value?.id ? value?.id : null)
                  }}/>
                <FormikInput
                  fullWidth
                  value={formik.values.region}
                  type={"autocomplete"}
                  fieldName={'region'}
                  label={"Region"}
                  options={regions}
                  accessKey={"name"}
                  onInputChange={(value) => {
                    getRegions({search: value, company_id: formik.values?.company?.id || null})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue(`region`, value)
                  }}
                  styleContainer={{mb: 2}}
                />
                <MDBox pb={2}>
                  <MDBox
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      formik.setFieldValue("logo", e.dataTransfer.files?.[0])
                    }}
                  >
                    <input
                      type='file'
                      accept='image/*'
                      name={`logo`}
                      id={`logo`}
                      style={{display: 'none'}}
                      onInput={(event) => {
                        formik.setFieldValue("logo", event.target.files[0]);
                      }}
                      onClick={(event) => {
                        event.target.value = null
                      }}
                    />
                    <InputDragAndDrop htmlFor={`logo`} label={'Brand logo'} errors={formik.errors} name={`logo`}/>
                  </MDBox>
                  <MDBox sx={ImagecardWrapContainer}>
                    {formik.values.logo && (
                      <MDBox sx={ImagecardContainer}>
                        <img
                          style={ImageCardImage}
                          src={formik.values.logo instanceof File ? URL.createObjectURL(formik.values.logo) : formik.values.logo}
                          onClick={() => {
                            openInNewTab(formik.values.logo instanceof File ? URL.createObjectURL(formik.values.logo) : formik.values.logo)
                          }}
                        />
                        <Icon
                          sx={ImagecardDeleteIcon}
                          fontSize='medium'
                          onClick={() => {
                            formik.setFieldValue("logo", null)
                          }}>
                          remove_circle
                        </Icon>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
                <MDBox pt={3}>
                  <MDBox
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      formik.setFieldValue("contract_template", e.dataTransfer.files?.[0])
                    }}
                  >
                    <input type='file' accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' name={`contract_template`} id={`contract_template`}
                           style={{display: 'none'}}
                           onInput={(event) => {
                             formik.setFieldValue("contract_template", event.target.files[0]);
                           }}
                           onClick={(event) => {
                             event.target.value = null
                           }}
                    />
                    <InputDragAndDrop htmlFor={`contract_template`} label={'Contract Template'} errors={formik.errors}
                                      name={`contract_template`}/>
                  </MDBox>
                  <MDBox>
                    {formik.values.contract_template && (
                      <MDBox sx={ImagecardContainer}>
                        <Icon sx={(th) => ({ml: pxToRem(11), mr: 2, mt: 2})} fontSize='medium' onClick={() => {
                          openInNewTab(formik.values.contract_template instanceof File ? URL.createObjectURL(formik.values.contract_template) : formik.values.contract_template)
                        }}>attachment</Icon>

                        <MDTypography
                          onClick={() => {
                            openInNewTab(formik.values.contract_template instanceof File ? URL.createObjectURL(formik.values.contract_template) : formik.values.contract_template)
                          }}
                          variant={'subtitle2'}
                          p={2}
                          sx={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer'
                          }}>
                          {formik.values.contract_template instanceof File ? formik.values.contract_template.name : formik.values.contract_template}
                        </MDTypography>
                        <Icon
                          sx={ImagecardDeleteIcon}
                          fontSize='medium'
                          onClick={() => formik.setFieldValue("contract_template", null)}>
                          remove_circle
                        </Icon>
                      </MDBox>
                    )
                    }
                  </MDBox>
                </MDBox>
                {/*<MDTypography variant={"p"} component={"p"} color={"error"} mt={2}>{JSON.stringify(formik.errors)}</MDTypography>*/}
                <MDBox sx={FileFieldsContainer}>

                  <FieldArray name="categories">
                    {(categoriesProps) => (
                      <BrandCategoriesForm
                        categoriesProps={categoriesProps}
                        values={formik.values}
                        setFieldValue={formik.setFieldValue}
                        categories={categories}
                      />
                    )}
                  </FieldArray>

                  <FieldArray name="packages">
                    {(packageProps) => (
                      <PackagesForm
                        packagesProps={packageProps}
                        values={formik.values}
                        setFieldValue={formik.setFieldValue}
                        categories={categories}
                        errors={formik.errors}
                      />
                    )}
                  </FieldArray>


                  <FieldArray name="drawings">
                    {(drawingsProps) => (
                      <DrawingsForm
                        formik={formik}
                        drawingsProps={drawingsProps}
                        values={formik.values}
                        setFieldValue={formik.setFieldValue}
                      />
                    )}
                  </FieldArray>

                </MDBox>
                <MDBox sx={[ActionsBox, {paddingLeft: 0}]}>
                  <MDButton
                    variant='outlined'
                    color='secondary'
                    sx={{width: pxToRem(120)}}
                    onClick={() => navigate(-1)}
                  >Cancel</MDButton>
                  <MDButton
                    variant="contained"
                    color="secondary"
                    loading={loading}
                    disabled={loading}
                    type='submit'
                    sx={{width: pxToRem(150), height: pxToRem(45)}}
                    onClick={() => formik.submitForm()}
                  >
                    Save
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </FormikProvider>
        </MDBox>
      </MDBox>
    </AdminLayout>
  )
}

export default observer(ManageBrandPage);
